/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
window.FontAwesomeConfig = {
  // autoReplaceSvg: false,
  searchPseudoElements: true
};

window.utf8Decode = (s) => {
  return s;
  // return decodeURIComponent(escape(s));
}

// Cookies (Use in rgpd, ...)
window.getCookie = (name) => {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));

  return match && match.length ? match[2] : null;
}
window.setCookie = (name, value, expires) => {
  // document.cookie = 'rgpd=approved; expires=Fri, 3 Aug 2020 20:47:11 UTC; path=/';
  var d = new Date();
  d = new Date((d.getTime() + 1000) * expires);
  document.cookie = name + '=' + value + '; expires=' + d.toGMTString() + '; path=/;SameSite=Lax;';
}

// Common

window.now = (() => {
  var d = new Date();
  var year = d.getFullYear();
  var month = ('0' + (d.getMonth() + 1)).slice(-2);
  var day = ('0' + d.getDate()).slice(-2);

  return year + '-' + month + '-' + day + ' ' + d.getHours() + ':' + d.getMinutes() + ':00';
})();

// To diff time between visit/registration/payment.
if (!getCookie('_first_now')) {
  if (now) {
    setCookie('_first_now', now, 2629800 * 13); // 2629800 = 1 month
    setCookie('_first_ua', encodeURIComponent(navigator.userAgent), 2629800 * 13);
    setCookie('_first_width', window.screen.width, 2629800 * 13);
    setCookie('_first_height', window.screen.height, 2629800 * 13);
    setCookie('_first_pixelDepth', window.screen.pixelDepth, 2629800 * 13);
    setCookie('_first_href', encodeURI(window.location.href).replace(/#.*/g, ''), 2629800 * 13);
    setCookie('_first_referer', encodeURI(document.referrer).replace(/#.*/g, ''), 2629800 * 13); // TODO What about the HTTPS redirect?
  }
}

// @see analytics.blade.php
// window.dataLayer = window.dataLayer || [];
// window.gtag = (() => {
//     // console.info(arguments);
//     if (typeof dataLayer != 'undefined') {
//         dataLayer.push(arguments);
//     } else {
//         console.error('[google] dataLayer is undefined!', arguments);
//     }
// });

window.tooltip = (() => {
  if (typeof $('[data-toggle="tooltip"]').tooltip != 'undefined') {
    $('[data-toggle="tooltip"]').tooltip().each(function () {
      var rendered = $(this).data('tooltip-rendered');
      if (!rendered) {
        $('[data-toggle="tooltip"]').tooltip({
          html: true
        });
        $(this).data('tooltip-rendered', true);
      }
    });
  }
  // if (typeof $('[data-toggle="tooltip"]').tooltip != 'undefined') {
  //   return $('[data-toggle="tooltip"]').tooltip({
  //     html: true
  //   });
  // }
});

// $('[data-toggle="tooltip"]').tooltip('hide')
// $('.ui-tooltip').hide();

// $(() => {
//   // Mutator
//   setInterval(tooltip, 2000);
// });

window.getScrollPercent = function () {
  var h = document.documentElement,
    b = document.body,
    st = 'scrollTop',
    sh = 'scrollHeight',
    percent = (h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight) * 100;

  // console.error('getScrollPercent', h[st], b[st], h[sh], b[sh], percent);
  percent = Math.round(percent);
  // console.error('getScrollPercent', $(document).scrollTop(), percent);

  if (isNaN(percent)) {
    percent = 0;
  }

  return percent;
}

window.scrollPublicHeader = function () {
  if ($(document).scrollTop() > 117) {
    $('#header-fixed-top').addClass('fixed-top');
  } else {
    // $('#.fixed-top').addClass('navbar-shrink');
    $('#header-fixed-top').removeClass('fixed-top');
  }

  var h = $('#header-fixed-top').height();
  h = Math.round(h);
  $('#header-fixed-top ~ .container, #header-fixed-top ~ #sections').css({ 'padding-top': h, });
  $('#header-fixed-top ~ .container-top, #header-fixed-top ~ #sections').css({ 'padding-top': h, });
}

window.scrollEditBar = function () {
  // $('#sections .public-rendered > div').each(function() {
  $('.edit-bar').parent().each(function () {
    var st = $(document).scrollTop()
    var tb = $(this).offset().top;
    var b = $(this).offset().top + $(this).parent().height() - 55;
    var t2 = st;

    var top = Math.max(tb, t2);
    top = Math.min(top, b);
    // top = Math.max(top, tb);
    top = Math.ceil(top); // + 10

    $(this).find('.edit-bar').css({ 'top': top });
  });
}

window.progressTimeBar = function () {
  var percent = window.getScrollPercent();
  $('.read-time-bar').css({ width: `${percent}%` });
}

window.pageOnScroll = () => {
  scrollPublicHeader();
  scrollEditBar();
  progressTimeBar();
}

$(window).on('scroll', () => {
  window.pageOnScroll();
});
$(() => {
  window.pageOnScroll();
});

// setInterval(() => {
//   // Mutator
//   scrollEditBar();
//   progressTimeBar();
// }, 500);

window.renderExternalIco = ((e) => {
  // noopener noreferrer
  setTimeout((e) => {
    $externals = $('.external');
    for (var i = 0; i < $externals.length; i++) {
      var $el = $($externals[i]);
      if ($el.attr('target') !== '_blank') {
        if ($el.hasClass('no-ico')) {
          $el.attr('target', '_blank').attr('rel', 'noopener');
        } else {
          $el.append('&nbsp;<i class="fas fa-external-link-alt fs-smaller"></i>')
            .attr('target', '_blank')
            .attr('rel', 'noopener');
        }
      }
    }
    $externals = $('.external-pdf');
    for (var i = 0; i < $externals.length; i++) {
      var $el = $($externals[i]);
      if ($el.attr('target') !== '_blank') {
        $el.prepend('<i class="fas fa-file-pdf fs-smaller text-danger ml-1"></i>&nbsp;')
          .attr('target', '_blank')
          .attr('rel', 'noopener');
      }
    }
  }, 100);
});
$(() => {
  renderExternalIco();
});

var windowObjectReference = null;
$(() => {
  $('span.btn-share').on('click', (e) => {
    var href = $(this).data('href');
    if (!href) {
      href = $(e.currentTarget).data('href');
    }
    var w = 500;
    var h = 400;
    var left = (screen.width / 2) - (w / 2);
    var top = (screen.height / 2) - (h / 2);
    var windowFeatures = 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=no, copyhistory=no, top=' + top + ',left=' + left + ',width=' + w + ',height=' + h;

    // console.error('span.btn-share', href, e, $(e.currentTarget));
    // if(windowObjectReference == null || windowObjectReference.closed) {
    windowObjectReference = window.open(href, 'windowShareRS', windowFeatures);
    // } else {
    //   windowObjectReference.focus();
    // }
  });
});

$(() => {
  $('.form-date-with-slashes').keyup(e => {
    e.preventDefault();
    var pos = this.selectionStart;
    var keycode = e.which || e.keyCode;
    var $el = $(e.currentTarget);
    var val = $el.val();
    if (val) {
      if (keycode != 8) { // 8 = BACKSPACE
        val = val.replace(/^([0-9]{2})$/g, '$1/');
        val = val.replace(/^([0-9]+)\/([0-9]{2})$/g, '$1/$2/');
      }
      // console.error(pos, val, e.keyCode, KeyboardEvent.key);
      $el.val(val);

      if (!/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/g.test(val)) {
        $el.addClass('is-invalid');
      } else {
        $el.removeClass('is-invalid');
      }
    }
  });
});

window.navByAnchor = (() => {
  // Hash navigation
  let hash = window.location.hash;
  if (hash == '#/undefined' || hash == '#/') {
    hash = null;
  }
  // console.error('hash', hash, window.location.hash);

  // Tab anchor navigation (nav-tabs)
  $('.nav-tabs .nav-link').click(e => {
    e.preventDefault();

    let h = this.hash;
    if (!h && e.target) {
      h = $(e.target)[0].hash;
    }
    // console.error('h', h, hash, window.location.hash);
    if (h) {
      window.location.hash = h;
      // console.error('window.location.hash (1) =', window.location.hash);
    }
  });

  if (!hash) {
    $('.nav-tabs .nav-item .dropdown-menu a').click(e => {
      e.preventDefault();

      let h = this.hash;
      if (!h && e.target) {
        h = $(e.target)[0].hash;
      }
      // console.error('h', h, hash, window.location.hash);
      if (h) {
        window.location.hash = h;
        // console.error('window.location.hash (2) =', window.location.hash);
      } else {
        console.error(this)
      }
    });
  }

  if (hash == '#/') {
    return false;
  }

  if (hash) {
    let $el = $(`.nav-tabs .nav-link[href="${hash}"]`);
    if ($el.length) {
      if (typeof $el.tab != 'undefined') { // == 'function'
        $el.tab('show');
      } else {
        $('.nav-tabs .nav-link').removeClass('active');
        $el.addClass('active');
      }
    } else if (!$el.length) {
      let $el = $(`.nav-tabs .nav-item [href="${hash}"]`);
      if ($el.length) {
        if (typeof $el.tab != 'undefined') { // == 'function'
          $el.tab('show');
        } else {
          $('.nav-tabs .nav-link').removeClass('active');
          $el.parent().parent().find('.nav-link').addClass('active');
        }
      }
    } else if (!$el.length) {
      hash = hash.replace(/#\//, '#');
      $el = $(`.nav-tabs .nav-link[href="${hash}"]`);
      if ($el.length) {
        if (typeof $el.tab != 'undefined') {
          $el.tab('show');
        } else {
          $('.nav-tabs .nav-link').removeClass('active');
          $el.addClass('active');
        }
      }
    }

    // console.error(`.nav-tabs .nav-link[href="${hash}"]`, $(`.nav-tabs .nav-link[href="${hash}"]`));

  } else {

    // console.error(hash, `.nav-tabs .nav-link[href="${hash}"]`);

    $el = $('.nav-tabs .nav-link:first');
    if (typeof $el.tab != 'undefined') {
      $el.tab('show');
    } else {
      $('.nav-tabs .nav-link').removeClass('active');
      $el.addClass('active');
    }
    // console.error(`.nav-tabs .nav-link:first`);
  }

  return hash;
});

$(() => {
  navByAnchor();
});

if ("onhashchange" in window) {
  window.onhashchange = (() => {
    navByAnchor();
  });
}

window.hasClass = (el, classname) => el && el.className && el.className.split(' ').indexOf(classname) >= 0;

window.parentWithClass = (el, classname) => {
  if (hasClass(el, classname)) {
    return el;
  }

  return el && el.parentNode && parentWithClass(el.parentNode, classname);
}

// // //cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.3/jquery.easing.min.js
// // //cdnjs.cloudflare.com/ajax/libs/jquery-easing/1.4.1/jquery.easing.min.js
// require('jquery.easing');

window.formatNumberToFr = (s) => {
  return s
    ? s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ").replace(/\./g, ',')
    : s;
}

window.formatPrice = (price, currency) => {
  return parseFloat(price).toFixed(2).toString().replace(".", ",") + '&#8239;€';
}

window.autodate = ((selector) => {
  $(selector).on('keyup', function (e) {
    var k = e.which;
    if (k == 8 || k == 37 || k == 39 || k == 46) { // Backspace, arrows, delete
      return false;
    }
    var v = $(this).val().replace(/[^0-9\/]+/g, '');
    if (v.match(/^\d{1}\//)) {
      v = '0' + v;
    }
    if (v.match(/^\d{2}\/\d{1}\//)) {
      v = v.replace(/^(\d{2})\/(\d{1})\//, '$1/0$2/');
    }
    if (v.match(/^\d{2}$/) !== null || v.match(/^\d{2}\/\d{2}$/) !== null) {
      v += '/';
    }
    for (var l of [2, 5]) {
      if (v.length >= l && v[l] != '/') {
        v = v.substring(0, l) + '/' + v.substring(l);
      }
    }
    v = v.replace(/\/\//, '/');
    window.$(this).val(v);
  });
});

window.autotab = ((selector) => {
  $(selector).on('keyup', function (e) {
    var $this = $(this);
    var k = e.which;
    if (!$this.val() && k == 8) { // Backspace
      var $previous = $this.prevAll(`${selector}[value=''], ${selector}:not([value])`)[0];
      if ($previous) {
        $previous.focus();
        // if (typeof $previous.val != 'undefined') {
        //   $previous.val($previous.val());
        // }

        return false;
      }
    }
    var ml = $this.attr('maxlength');
    ml = parseInt(ml, 10);
    var l = $this.val().length;
    if (l >= ml) {
      var $next = $this.nextAll(`${selector}[value=''], ${selector}:not([value])`)[0];
      if ($next) {
        $next.focus();
      }
    }
  });
});

$.fn.isInViewport = function () {
  // var el = this;
  // var el = window;
  // if (window.namespace == 'admin' || window.namespace == 'customer') {
  //   el = '#page-content-wrapper';
  // }

  if (!$(this).length) {
    return false;
  }

  var elementTop = $(this).offset().top;
  if (elementTop == 0) {
    elementTop = $(this).parent().offset().top;
  }
  var elementBottom = elementTop + $(this).outerHeight();
  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();
  // console.error('isInViewport', [elementTop, elementBottom, viewportTop, viewportBottom]);

  return elementBottom > viewportTop && elementTop < viewportBottom;
};

// TODO Set scroll with #page-content-wrapper
window.scrollTo = (target, scrollable, ymod) => {
  setTimeout(() => {
    if ($(target).length) {
      var top = $(target).offset().top;
      if (!top && target.indexOf('#') === 0) {
        top = $(`[for=${target.substr(1)}]`).offset()
          ? $(`[for=${target.substr(1)}]`).offset().top
          : null;
      }
      if (!top) {
        top = $(target).parent().offset().top;
      }
      top = Math.round(top);
      if (!scrollable && typeof $(target).isInViewport != 'undefined'
        && $(target).isInViewport()) {
        // console.error('scrollTo Not in viewport');

        return false;
      }

      var el = 'html, body';
      if (window.namespace == 'admin' || window.namespace == 'customer') {
        el = '#page-content-wrapper';
      }
      if (scrollable) {
        el = scrollable;
        top -= $(scrollable).offset().top;
      }
      if (typeof ymod != 'undefined') {
        top += ymod;
      }
      $(el).stop().animate({
        scrollTop: top - 75
        // }, 500, 'easeInOutExpo'); // $.easing
      }, 500, 'easeOutQuart');

      // console.error('scrollTo', top);

    } else {
      console.error('scrollTo Target not found', target);
    }
  }, 100);

  return true;
};

let pageScrollId = null;
let updatePageScroll = (() => {
  let n = 0;
  $('.page-scroll').each(() => {
    if (!$(this).data('scrollable')) {
      console.error('updatePageScroll')
      console.error($(this));
      $(this).bind('click', (event) => {
        var $anchor = $(this);
        var target = $anchor.attr('href');
        if ($anchor.data('href')) {
          target = $anchor.data('href');
        }
        var ymod = null;
        if ($anchor.data('ymod')) {
          ymod = $anchor.data('ymod');
        }
        console.error('scrollTo', target, ymod);
        scrollTo(target, null, ymod);
        event.preventDefault();
      });
      $(this).data('scrollable', '1');
      n++;
    }
  });
  clearTimeout(pageScrollId);
  pageScrollId = setTimeout(updatePageScroll, 200);

  return true;
});

window.updatePageScroll = updatePageScroll;

$(() => {
  updatePageScroll();
});

window.Accordion = {
  $container: null,
  $links: [],
  init: (target) => {
    this.$container = $('#' + target);
    if (this.$container) {
      this.$links = this.$container.find('.accordion');
      this.$links.each(() => {
        $(this).on('click', (e) => {
          Accordion.click($(this));
        });
      });
    }

    return true;
  },
  click: ($el) => {
    this.hide();
    var href = $el.attr('href');
    var $target = $(href);
    if ($target.is(':visible')) {
      $target.hide();
    } else {
      $target.show();
    }
  },
  hide: () => {
    this.$links.each(() => {
      var href = $(this).attr('href');
      var $target = $(href);
      $($target).hide();
    });

    return true;
  }
};
$(() => {
  Accordion.init('accordion');
})

// module.exports = {
//   methods: Accordion.init
// };

// Message from parent window (iframe)
window.quitQcm = () => {
  /*
  2020-09-15 Debug. Display swich portrait/landscape corrupted w iphone.
  return false;
  */

  var isCustomerArea = RegExp('/u/c/').test(window.location.href);
  if (!isCustomerArea) {
    return null;
  }

  if (true || $('body').hasClass('body-with-qcm')) {
    console.log('[QCM] quit', arguments);

    /*
    // TypeError: window.gtag is not a function
    window.gtag('event', 'qcm', {
        'event_category': 'close',
        'event_label': window.location.hash
    });
    */

    $('body').removeClass('body-with-qcm');

    if (arguments.length && arguments[0] == 'menu') {
      return false;
    }

    $('body').removeClass('registration');
    $('body').css('overflow', 'auto');
    $('.mcq-modal-histo').hide();

    let isAlreadyStatsPage = RegExp('/u/c/[0-9]+-stat.*').test(window.location.href);
    if (isAlreadyStatsPage) {
      return null;
    }

    let win = window.parent;
    let isNotParent = RegExp('^#/qcm/').test(window.location.hash);
    if (isNotParent || !win) {
      win = window;
    }
    let isHash = RegExp('^#/qcm/').test(win.location.hash);
    let isLaunch = !win.length;
    if (isHash || window.history.length > 1) {
      if (window.history.length > 1) {
        window.history.go(-1);
      } else {
        window.location.href = '#nohist';
      }
    } else if (isLaunch) {
      window.opener = window;
      window.close();
    }
  } else {
    console.log('[QCM] already closed');
    // Just is case... @see /u/c/23-statistiques
    $('.mcq-modal-histo').hide();
    $('body').css('overflow', 'auto');
  }
}

window.openQcm = (() => {

  console.log('[QCM] open', arguments);
  // window.scrollTo('body', 0);

  // Doc PDF de Xavier
  var daQcm = document.getElementById('idFrameDuQcm');
  if (daQcm && daQcm.contentWindow) {
    window.addEventListener("keydown", daQcm.contentWindow.onQcmKeyEvent);
  }

  $('body').addClass('body-with-qcm');
  $('body').css('overflow', 'hidden');

  if (/free/.test(window.location.hash)) {
    $('body').addClass('registration');
    $('html, body').scrollTop(0);
  }

  // TypeError: window.gtag is not a function
  // window.gtag('event', 'qcm', {
  //     'event_category': 'open',
  //     'event_label': window.location.hash
  // });

  $('.body-with-qcm').find('.navbar').find('a[href]').click(e => {
    // Navbar btns
    quitQcm('menu');
  });
  $('.body-with-qcm').find('.navbar').find('a[onclick]').on('click', (e) => {
    // Logout btn
    quitQcm('menu');
  });
});

// Show password
let showPwd = false;
window.switchPwd = (() => {
  showPwd = !showPwd;
  if (showPwd) {
    $('#show-password').find('i').addClass('fa-eye-slash').removeClass('fa-eye');
    $('input[name=password]').attr('type', 'text');
    $('input[name=password_confirmation]').attr('type', 'text');
  } else {
    $('#show-password').find('i').addClass('fa-eye').removeClass('fa-eye-slash');
    $('input[name=password]').attr('type', 'password');
    $('input[name=password_confirmation]').attr('type', 'password');
  }
});

// https://developer.mozilla.org/fr/docs/Web/API/Window/postMessage
// window.parent.postMessage('qcmQuit',"*");
window.addEventListener("message", receiveMessage, false);

/**
 * @param MessageEvent e
 */
function receiveMessage(e) {
  // if (e.data) {
  //     // console.error('receiveMessage', e);
  // }
  if (e.data == 'qcmQuit') {
    quitQcm();
  }
}

// Right click on video
// setInterval(() => {
//   // Mutator
//   $('video').bind('contextmenu', (e) => {
//     return false;
//   });
// }, 500);

// User-Agent
axios.get(`/xhr/ua?w=${window.screen.width}&h=${window.screen.height}&pd=${window.screen.pixelDepth}&href=${encodeURI(window.location.href).replace(/#.*/g, '')}&referer=${encodeURI(document.referrer).replace(/#.*/g, '')}`)
  .then(response => {
    // console.error('ua', response.data)
    // console.error(response);
  })
  .catch(error => {
    console.error('error', error);
  });

// ANTS_ENABLED
axios.get(`/xhr/ants-enabled`)
  .then(response => {
    window.ANTS_ENABLED = response.data;
  })
  .catch(error => {
    console.error('error', error);
  });

// APP_ANTS_ENABLED
// window.ANTS_ENABLED = await axios.get('/xhr/ants-enabled').catch((err) => {
//   console.log('err', err);
// });
// console.error('window.ANTS_ENABLED', window.ANTS_ENABLED)

// select2
let withPrefectures = true;
if (withPrefectures) {
  function formatRepoPrefecture(repo) {
    if (repo.loading) {
      return repo.text;
    }
    var markup = "<div class='select2-result-repository clearfix'>" +
      "<div class='select2-result-repository__meta'>" +
      "<div class='select2-result-repository__title'>" + repo.name + ' ' + repo.postal_code + "</div>";
    if (repo.region && repo.department) {
      markup += "<div class='select2-result-repository__description'>" + repo.region + ' > ' + repo.department + "</div>";
    }
    markup += "</div></div>";

    return markup;
  }

  function formatRepoPrefectureSelection(repo) {
    if (repo.name) {
      $.ajax({
        url: '/prefectures/infos',
        type: 'PUT',
        dataType: 'json',
        // data: "_token=" + _token + "&id=" + repo.id
        data: "_token=" + window.Lar_avel.csrfToken + "&id=" + repo.id
      })
        .done(function (data) {
          if (data && data.view) {
            $('#snippet').html(data.view).show();
            $('#list').hide();
          } else {
            $('#snippet').html('').hide();
            $('#list').show();
          }
        });
      return repo.name + ' ' + repo.postal_code;
    } else {
      return repo.text;
    }
  }
  
  $(() => {
    if ($('#exam_department-select2').length) {
      $('#exam_department-select2').select2({
        language: 'fr',
        ajax: {
          url: "/prefectures/suggest",
          type: 'PUT',
          dataType: 'json',
          delay: 50,
          data: function (params) {
            return {
              _token: window.Lar_avel.csrfToken, // _token,
              q: params.term, // search term
              page: params.page
            };
          },
          processResults: function (data, params) {
            params.page = params.page || 1;
            return {
              results: data.localities.data,
              pagination: {
                more: (params.page * 30) < data.localities.total
              }
            };
          },
          cache: false
        },
        escapeMarkup: function (markup) {
          return markup;
        }, // let our custom formatter work
        minimumInputLength: 1,
        templateResult: formatRepoPrefecture, // omitted for brevity, see the source of this page
        templateSelection: formatRepoPrefectureSelection // omitted for brevity, see the source of this page
      })
        .on("select2:open", function (e) {
          $('.select2-search__field').val($('#address_city').val());
        });
    }
  });
}

window.logger = {
  types: ['debug', 'error', 'group', 'groupEnd', 'info', 'log', 'warn'],
  old: {},
  pub: {},
  init: () => {
    for (var type of window.logger.types) {
      window.logger.old[type] = window.console[type];
    }
  },
  enable: () => {
    for (var type of window.logger.types) {
      window.console[type] = window.logger.old[type];
    }

    return 'Logger enabled';
  },
  disable: () => {
    for (var type of window.logger.types) {
      window.console[type] = () => { };
    }

    return 'Logger disabled';
  }
};
window.logger.init();

window.rotated = false;
window.autoRotatePopIn = () => {
  // if (window.innerWidth < window.innerHeight) {
  if (window.rotated) {
    $('.zoom-fullscreen-container').removeClass('rotate');
  } else {
    var $containers = $('.zoom-fullscreen-container:visible');
    if ($containers.length) {
      $containers.each(function () {
        if (!$(this).hasClass('rotate')) {
          $(this).addClass('rotate');
        }
      });
    }
  }

  window.rotated = !window.rotated;
}

// window.addEventListener("resize", () => {
//   autoRotatePopIn();
// });

window.createFullscreenContainer = () => {
  const newDiv = document.createElement("div");
  newDiv.id = 'zoom-fullscreen-main';
  newDiv.innerHTML = `
  <div class="zoom-fullscreen-mask">
    <div class="zoom-fullscreen-wrapper" style="cursor: zoom-out" onclick="$('#zoom-fullscreen-main').fadeToggle('fast', 'linear');">
      <div class="zoom-fullscreen-container">
        <img id="zoom-fullscreen-img" class="box-shadow-hard" src="" alt="" />
        <div class="close"><i class="fas fa-times"></i></div>
        <div class="alt"><span>Text alternatif</span></div>
      </div>
    </div>
    <div class="zoom-fullscreen-options">
      <button onclick="window.autoRotatePopIn()" class="btn btn-dark btn-lg">
        <i class="fa-solid fa-camera-rotate"></i>
      </button>
    </div>
  </div>`;
  const currentDiv = document.getElementById('body');
  document.body.insertBefore(newDiv, currentDiv);
}

window.fullscreenImgSrc = (img) => {
  if (!img.src) {
    return false;
  }
  $('#zoom-fullscreen-img').attr('src', img.src);
  var alt = img.alt ? img.alt : '';
  $('#zoom-fullscreen-img').attr('alt', alt);
  if (alt) {
    $('#zoom-fullscreen-main .alt > span').show().html(alt);
  } else {
    $('#zoom-fullscreen-main .alt > span').hide();
  }
};

window.activateFullscreenImgs = () => {
  $('img.zoom-fullscreen-click').each(function () {
    if (!$(this).hasClass('zoom-fullscreen-rendered')
      && !$(this).siblings().first().hasClass('zoom-icon')) {

      if ($(this).parent().css('position') != 'relative') {
        $(this).parent().addClass('position-relative');
      }
    }

    $(this).on('click', () => {
      if (!$('#zoom-fullscreen-main').length || $('#zoom-fullscreen-main').is(':hidden')) {
        fullscreenImgSrc(this);
        $('#zoom-fullscreen-main').fadeToggle('fast', 'linear');
      }
    })
      .addClass('zoom-fullscreen-rendered');
  });
}

createFullscreenContainer();

setInterval(() => {
  // @see Mutator
  document.documentElement.style.setProperty('--sidebar-width-current',
    parseInt($('#sidebar-wrapper').width()) + 'px');
}, 10);

var Mutator = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
const observer = new Mutator(list => {
  tooltip();
  scrollEditBar();
  progressTimeBar();
  $('video').bind('contextmenu', (e) => {
    return false;
  });
  activateFullscreenImgs();

  document.documentElement.style.setProperty('--inner-width', `${window.innerWidth}px`);
  document.documentElement.style.setProperty('--inner-height', `${window.innerHeight}px`);
  document.documentElement.style.setProperty('--sidebar-width-current', parseInt($('#sidebar-wrapper').width()) + 'px');
});

observer.observe(document.body, { attributes: true, childList: true, subtree: true });
